import axios from 'axios';
import SERVER_URL from '../../config/server';
import { LOGIN_URL, SIGNUP_URL } from '../../config/server_urls';

const signup = async (userCredentials) => {
  return axios.post(SERVER_URL + SIGNUP_URL, userCredentials)
      .then((response) => handleSignupSuccess(response))
      .catch((error) => handleSignupError(error));
};

const handleSignupSuccess = (response) => {
  return {
    success: true,
    message: response.data.message,
  };
};

const handleSignupError = (error) => {
  if (error.response === undefined) {
    return {
      success: false,
      message: 'There was some network error!',
    };
  } else {
    return {
      success: false,
      message: error.response.data,
    };
  }
};

const getAuthToken = async (userCredentials) => {
  return axios.post(SERVER_URL + LOGIN_URL, userCredentials)
      .then((response) => handleLoginSuccess(response))
      .catch((error) => handleError(error));
};

const handleLoginSuccess = (response) => {
  return {
    success: true,
    token: response.data.token,
  };
};

const handleError = (error) => {
  if (error.response === undefined) {
    return {
      success: false,
      message: 'There was some network error!',
    };
  } else {
    return {
      success: false,
      message: message,
    };
  }
};

export {
  signup,
  getAuthToken,
};
