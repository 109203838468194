import React from 'react';
import Signup from './signup';
import './auth.css';
import logo from '../assets/platform_logo.png';

const SignupPage = () => (
  <div className="auth-page-outer-ctn">
    <div className="left-blue-bg_ctn"/>
    <div className="auth-page_ctn">
      <div className="action-ctn">
        <img alt="Innovation Platform Logo" src={logo}/>

        <div>
          <div className="form-welcome">
            <h2>Witamy na pokładzie!</h2>
            <p>Podaj swojego maila i hasło, aby utworzyć konto</p>
          </div>
          <Signup/>
          <div className="form-alternative-actions_ctn">
            <p>Masz już konto?  <span><a>Zaloguj się</a></span></p>
            <p><span><a>Utwórz konto</a> dla koła naukowego, firmy lub funduszu inwestycyjnego</span></p>
          </div>
        </div>
        <div className="form-footer_ctn">
          <a>Polytyka prywatności</a>
          <p>•</p>
          <a>Regulamin</a>
        </div>
      </div>
      <div className="info-ctn">
        <h1>Razem stworzymy świat przyszłości.</h1>
        <p>Innovations Platform łączy ludzi biznesu, nauki i technologii.</p>
      </div>
    </div>
  </div>

);

export default SignupPage;
