import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './auth/login';
import Alert from './components/alert';
import {EMAIL_VERIFICATION, HOME_PATH, LOGIN_PATH, SIGN_UP_PATH} from './config/page_paths';
import EmailVerification from './auth/email-verification';
import SignupPage from './auth/signup-page';
import MedBiz from './auth/medbiz-registration';

function App() {
  return (
    <BrowserRouter>
      <Alert/>
      <div className="App">
        <Routes>
          <Route path={HOME_PATH} element={<MedBiz/>}/>
          <Route path={SIGN_UP_PATH} element={<SignupPage/>}/>
          <Route path={LOGIN_PATH} element={<Login/>}/>
          <Route path={EMAIL_VERIFICATION} element={<EmailVerification/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
