import {ALERT, CLEAR_ALERT, LOGIN, LOGOUT, REFRESH} from './action_types';

const initState = {
  isLogged: false,
  authObj: {
    email: '',
    token: '',
  },
  refresh: false,
  isAlert: false,
  alert: {
    type: 1,
    message: '',
  },
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case REFRESH: {
      return {
        ...state,
        refresh: !state.refresh,
      };
    }
    case ALERT: {
      return {
        ...state,
        isAlert: true,
        alert: action.data,
      };
    }
    case CLEAR_ALERT: {
      return {
        ...state,
        isAlert: false,
      };
    }
    case LOGIN: {
      return {
        ...state,
        isLogged: true,
        authObj: action.data,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLogged: false,
        authObj: {},
      };
    }
    default:
      return state;
  }
};

export default reducer;
