import React from 'react';
import { useSearchParams } from 'react-router-dom';
import verifyEmail from './api/email-verification-api';

const TOKEN_NOT_GIVEN = 'no token';
const DURING_VERIFICATION = 'during';
const VERIFICATION_SUCCEEDED = 'succeeded';
const VERIFICATION_ERROR = 'error';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = React.useState(DURING_VERIFICATION);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    const token = searchParams.get('token');
    if (token === null) {
      setVerificationStatus(TOKEN_NOT_GIVEN);
    } else {
      handleVerification(token);
    }
  }, [searchParams]);

  const handleVerification = async (token) => {
    const result = await verifyEmail(token);
    console.log(result);
    if (result.success) {
      setVerificationStatus(VERIFICATION_SUCCEEDED);
    } else {
      setErrorMessage(result.message);
      setVerificationStatus(VERIFICATION_ERROR);
    }
  };

  const renderVerificationStatus = () => {
    switch (verificationStatus) {
      case DURING_VERIFICATION:
        return (
          <p>We are verifying your account...</p>
        );
      case VERIFICATION_SUCCEEDED:
        return (
          <p>Your account was verified.</p>
        );
      case VERIFICATION_ERROR:
        return (
          <p>We could not verify your account ({errorMessage})</p>
        );
      case TOKEN_NOT_GIVEN:
        return (
          <p>You did not provide verification token</p>
        );
      default:
        return (
          <p>We are verifying your account...</p>
        );
    }
  };

  return (
    <div>
      <p>Email verification</p>
      {renderVerificationStatus()}
    </div>
  );
};

export default EmailVerification;
