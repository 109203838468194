import React from 'react';
import './text-input.css';

const TextInput = (
    {
      label,
      name,
      inputType,
      value,
      setValueChange,
      placeholder,
      infoMsg,
      errorMsg,
    }) => {
  if (errorMsg !== undefined && infoMsg !== undefined) {
    throw new Error('info and error message given at the same time. (it does not look nice.');
  }

  return (
    <div className='text-input_ctn'>
      <label>
        {label}
        <input
          type={inputType}
          name={name}
          value={value}
          onChange={(event) => setValueChange(event.target.value)}
          placeholder={placeholder}
        />
      </label>
      <div className='below-msg_ctn'>
        {
          infoMsg !== undefined && (
            <p className='info-msg'>{infoMsg}</p>
          )
        }
        {
          errorMsg !== undefined && (
            <p className='error-msg'>{errorMsg}</p>
          )
        }
      </div>
    </div>
  );
};

export default TextInput;
