import React from 'react';
import {connect} from 'react-redux';
import {_cleanAlert} from '../redux/actions';
import './alert.css';

const ALERT_DISPLAY_TIME = 5000;
const ERROR_ALERT = 'error-alert';
const INFO_ALERT = 'info-alert';

const Alert = ({isAlert, alert, alertOff}) => {
  React.useEffect(() => {
    setTimeout(() => alertOff(), ALERT_DISPLAY_TIME);
  }, [isAlert, alertOff]);

  const getAlertClass = () => {
    if (alert.type === ERROR_ALERT) {
      return 'error-bg';
    } else {
      return 'info-bg';
    }
  };

  return (
    <div className="alert_ctn">
      {
        isAlert &&
        (
          <div className={getAlertClass()}>
            {alert.message}
          </div>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAlert: state.isAlert,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertOff: () => {
    dispatch(_cleanAlert());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);

export {
  ERROR_ALERT, INFO_ALERT,
};
