import React from 'react';
import styles from './medbiz-registration.module.css';
import logo from '../assets/platform_logo.png';

const MedBiz = () => {
  return (
    <div className={styles.ctn}>
      <div className={styles.innerCtn}>
        <img alt="Innovation Platform Logo" src={logo}/>
        <div className={styles.fromCtn}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSd5v4OXt3V_IW6-Hp25slom9ejjV3IEnFaCUEcWiqeHn0Skrg/viewform?embedded=true"
            width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default MedBiz;
