import React, {useState} from 'react';
import {signup} from './api/auth-api';
import {_showAlert} from '../redux/actions';
import {connect} from 'react-redux';
import {ERROR_ALERT, INFO_ALERT} from '../components/alert';
import {LOGIN_PATH} from '../config/page_paths';
import {useNavigate} from 'react-router-dom';
import TextInput from '../components/inputs/text-input';
import Button from '../components/inputs/button';
import Checkbox from '../components/inputs/checkbox';

const Signup = ({alertOn}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [check, setCheck] = useState(false);
  const [emailError, setEmailError] = useState(undefined);
  const [passwordError, setPasswordError] = useState(undefined);

  React.useEffect(() => {
    setEmailError(undefined);
  }, [email]);

  React.useEffect(() => {
    setPasswordError(undefined);
  }, [password]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm() === false) {
      return;
    }

    const result = await signup({
      email: email,
      password: password,
    });
    alertOn({
      type: result.success ? INFO_ALERT : ERROR_ALERT,
      message: result.message,
    });
    if (result.success) {
      navigate(LOGIN_PATH);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (isEmailAddressNotValid()) {
      setEmailError('Podany email ma zły format.');
      isValid = false;
    }

    if (isPasswordToSimple()) {
      setPasswordError('Podane hasło jest za proste. Musi zawiwrać conajmiej jedną cyfre i litere.');
      isValid = false;
    }
    if (isPasswordToShort()) {
      setPasswordError('Podane hasło jest za krótkie. Hasło musi mieć minimum 9 znaków.');
      isValid = false;
    }

    if (check === false) {
      alertOn({
        type: ERROR_ALERT,
        message: 'Musisz zgodzić się na przetwarzanie twoich danych.',
      });
      return false;
    }

    return isValid;
  };

  const isEmailAddressNotValid = () => {
    return !email.match(/.+@.+/);
  };

  const isPasswordToShort = () => {
    return password.length < 9;
  };

  const isPasswordToSimple = () => {
    return (password.search(/[a-z]/i) < 0 || password.search(/[0-9]/) < 0);
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event)}>
        <TextInput
          label={'Email:'}
          name={'email'}
          inputType={'email'}
          value={email}
          setValueChange={setEmail}
          placeholder={'np. przykładowy@gmail.com'}
          errorMsg={emailError}
        />
        <TextInput
          label={'Hasło:'}
          name={'password'}
          inputType={'password'}
          value={password}
          setValueChange={setPassword}
          placeholder={'Wpisz hasło'}
          errorMsg={passwordError}
        />
        <Checkbox
          label={'Zgadzam się na przetwarzanie danych osobowych'}
          value={check}
          setValueChange={setCheck}
        />
        <Button>Zarejestruj się</Button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  alertOn: (alertObj) => {
    dispatch(_showAlert(alertObj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
