import React from 'react';
import './checkbox.css';

const Checkbox = (
    {
      label,
      value,
      setValueChange,
    }) => (
  <div className='checkbox-ctn' onClick={() => setValueChange(!value)}>
    <div className='checkbox-outer'>
      {
        value &&
        <div className='checkbox-inner'/>
      }
    </div>
    <p>{label}</p>
  </div>
);

export default Checkbox;
