import axios from 'axios';
import SERVER_URL from '../../config/server';
import { EMAIL_VERIFICATION_URL } from '../../config/server_urls';

const verifyEmail = async (token) => {
  return axios.put(SERVER_URL + EMAIL_VERIFICATION_URL + '?token=' + token)
      .then(() => handleSuccess())
      .catch((error) => handleError(error));
};

const handleSuccess = () => {
  return {
    success: true,
  };
};

const handleError = (error) => {
  return {
    success: false,
    message: 'There was some error verifying your account',
  };
};

export default verifyEmail;
