import React from 'react';
import './button.css';

const STD_BUTTON = 'std';
const MINOR_BUTTON = 'minor';

const Button = (
    {
      onClick,
      children,
      style,
      className,
      type=STD_BUTTON,
    }) => {
  const getBtnStyle = (type) => {
    switch (type) {
      case STD_BUTTON:
        return 'std-btn';
      case MINOR_BUTTON:
        return 'minor-btn';
      default:
        return 'std-btn_ctn';
    }
  };

  return (
    <div
      className={'btn_ctn ' + getBtnStyle(type) + ' ' + className}
      style={style}
    >
      <button onClick={() => onClick()}>
        {children}
      </button>
    </div>
  );
};

export default Button;

export {
  STD_BUTTON,
  MINOR_BUTTON,
};
