import React, {useState} from 'react';
import {getAuthToken} from './api/auth-api';
import {ERROR_ALERT, INFO_ALERT} from '../components/alert';
import {_login, _showAlert} from '../redux/actions';
import {connect} from 'react-redux';
import {HOME_PATH} from '../config/page_paths';
import {useNavigate} from 'react-router-dom';

const Login = ({alertOn, login}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await getAuthToken({
      email: email,
      password: password,
    });
    alertOn({
      type: result.success ? INFO_ALERT : ERROR_ALERT,
      message: result.message,
    });

    if (result.success) {
      login({
        token: result.token,
        email: email,
      });
      navigate(HOME_PATH);
    }
  };

  return (
    <div>
      <p>Login</p>
      <form onSubmit={(event) => handleSubmit(event)}>
        <label>
          Email:
          <input
            type="text"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </label>
        <label>
          Hasło:
          <input
            type="text"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </label>
        <input type="submit" value="Zaloguj się" />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  alertOn: (alertObj) => dispatch(_showAlert(alertObj)),
  login: (authObj) => dispatch(_login(authObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
