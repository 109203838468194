import {ALERT, CLEAR_ALERT, LOGIN, LOGOUT, REFRESH} from './action_types';

export const _login = (authObj) => ({
  type: LOGIN,
  data: authObj,
});

export const _logout = () => ({
  type: LOGOUT,
});

export const _refresh = () => ({
  type: REFRESH,
});

export const _showAlert = (alert) => ({
  type: ALERT,
  data: alert,
});

export const _cleanAlert = () => ({
  type: CLEAR_ALERT,
});
